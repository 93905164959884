@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: "test geograph";
  src: url(assets/fonts/test-geograph-light.woff2);
  font-weight: 200;
}
@font-face {
  font-family: "test geograph";
  src: url(assets/fonts/test-geograph-regular.woff2);
  font-weight: 400;
}

@font-face {
  font-family: "test geograph";
  src: url(assets/fonts/test-geograph-medium.woff2);
  font-weight: 500;
}

@font-face {
  font-family: "test geograph";
  src: url(assets/fonts/test-geograph-bold.woff2);
  font-weight: 700;
}
@font-face {
  font-family: "test geograph";
  src: url(assets/fonts/test-geograph-black.woff2);
  font-weight: 900;
}
html,
body {
  margin: 0;
  font-family: "test geograph", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden !important;
  scroll-behavior: smooth;
  background-color: #fff8ee;
}
main {
  /* min-height: calc(100vh - 1px) !important; */
  overflow-x: hidden !important;
}
/* main > div > div {
  min-height: calc(100vh - 128px) !important;
} */
.phantasm-logo {
  box-shadow: -3px 3px 2px 0px #690000;
}
.home {
  min-height: calc(100vh - 150px);
}
