.time-unit {
}

.number {
  /* font-size: 48px;
  font-weight: bold; */
}

@media (max-width: 1264px) {
  .time-unit {
    font-size: 15px;
  }
  .number {
    font-size: 40px;
  }
}

@media (max-width: 1072px) {
  .time-unit {
    font-size: 14px;
  }
  .number {
    font-size: 35px;
  }
}

@media (max-width: 1025px) {
  .time-unit {
    font-size: 12px;
  }
  .number {
    font-size: 30px;
  }
}
@media (max-width: 942px) {
  .time-unit {
    font-size: 11px;
  }
  .number {
    font-size: 25px;
  }
}
